import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./headerStyle.css";
import { ScrollLink } from "react-scroll";
import Utils from "../../utils/utility";
import { BrowserRouter, Link } from "react-router-dom";
import Constant from "../../utils/constants";

const Header = forwardRef(({ bgColor }, ref) => {
  const [activeTab, changeTab] = useState(Constant.HOME_TAB);

  const [dropMenu, toggleDropMenu] = useState(false);

  // let menuToggle = document.querySelector(".toggle");
  // let navigation = document.querySelector(".navigation");

  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        setSmall(window.pageYOffset > 200);

        // if (window.pageYOffset < 300) {
        // 	toggleDropMenu(false);
        // }
      });
    }
  }, []);

  useImperativeHandle(ref, () => ({
    onTabChanged(tab) {
      changeTab(tab);
    },
  }));

  const navItem = (tabName, sectionId) => {
    return (
      <li>
        <Link
          to={`/${sectionId.replace("-section", "")}`}
          //   onClick={sectionId ? Utils.scrollTo(sectionId) : ""}
          className={`${tabName === activeTab && "active"}`}
          onClick={() => {
            let sectionEL = document.getElementById(sectionId);
            sectionEL &&
              sectionEL.scrollIntoView({ behavior: "smooth", block: "start" });

            changeTab(tabName);

            toggleDropMenu(!dropMenu);
          }}
        >
          {tabName}
        </Link>
      </li>
    );
  };

  return (
    <header
      className={`header ${small ? "header-on-scrolled" : ""}`}
      bg-content={bgColor ? bgColor : "black"}
    >
      <div>
        <Link
          //   href="#home-section"
          to={"home"}
          onClick={() => {
            Utils.scrollToTop();
            changeTab(Constant.HOME_TAB);
          }}
          className="logo"
          style={{ display: "flex", alignItems: "center" }}
          // style={{window.innerWidth > 768 ? '800px' : '400px'}}
        >
          <img
            className="logo-img"
            src="/assets/icons/jmbc-logo.png"
            alt="Jai Maa Bhagwati Confectionery Udyog | JMBC Candy"
          />
          {"Candies"}
        </Link>
      </div>

      <div
        className={`toggle ${dropMenu ? "active" : ""}`}
        style={{ background: "/assets/icons/menu.svg" }}
        onClick={() => {
          toggleDropMenu(!dropMenu);
          // menuToggle.classList.toggle("active");
          // navigation.classList.toggle("active");
        }}
      ></div>
      <ul className={`navigation ${dropMenu ? "active" : ""}`}>
        {dropMenu && (
          <img
            className="close-menu-btn"
            src="/assets/icons/close-cross.svg"
            onClick={() => {
              toggleDropMenu(!dropMenu);
            }}
            alt="close"
          />
        )}
        {navItem(Constant.HOME_TAB, "home-section")}
        {navItem(Constant.PRODUCT_TAB, "products-section")}
        {/* {navItem("What's New")} */}
        {navItem(Constant.ABOUT_TAB, "about-section")}
        {navItem(Constant.CONTACT_TAB, "footer-section")}
      </ul>
    </header>
  );
});

export default Header;

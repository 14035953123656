import React, { useState, useEffect } from "react";
// import ReactDOM from 'react-dom';
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import logo from "./logo.svg";
import "./App.css";

import HomePage from "./pages/homePage/homePage";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import { BrowserRouter } from "react-router-dom";

import { database } from "./firebase";

function App() {
  const userCookieName = "returningVisitor";

  var hitCounter;
  var db;
  useEffect(() => {
    hitCounter = document.getElementById("hit-counter");
    db = database.ref("totalHits");

    hitCounter.style.display = "none";

    checkUserCookie(userCookieName);

    db.on("value", (snapshot) => {
      hitCounter.textContent = snapshot.val() + " Sweet Visitors";
    });
  }, []);

  function checkUserCookie(userCookieName) {
    const regEx = new RegExp(userCookieName, "g");
    const cookieExists = document.cookie.match(regEx);
    if (cookieExists != null) {
      hitCounter.style.display = "block";
    } else {
      createUserCookie(userCookieName);
      db.transaction(
        (totalHits) => totalHits + 1,
        (error) => {
          if (error) {
            console.log(error);
          } else {
            hitCounter.style.display = "inline-block";
          }
        }
      );
    }
  }

  function createUserCookie(userCookieName) {
    const userCookieValue = "Yes";
    const userCookieDays = 4;
    let expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + userCookieDays);
    document.cookie =
      userCookieName +
      "=" +
      userCookieValue +
      "; expires=" +
      expiryDate.toGMTString() +
      "path=/";
  }

  // ReactDOM.render(
  //   <MessengerCustomerChat
  //     pageId="100245525873088"
  //     appId="<APP_ID>"
  //     htmlRef="<REF_STRING>"
  //   />,
  //   document.getElementById('demo')
  // );  

  // var chatbox = document.getElementById("fb-customer-chat");
  // chatbox.setAttribute("page_id", "100245525873088");
  // chatbox.setAttribute("attribution", "biz_inbox");

  // window.fbAsyncInit = function () {
  //   FB.init({
  //     xfbml: true,
  //     version: "v12.0",
  //   });
  // };

  // (function (d, s, id) {
  //   var js,
  //     fjs = d.getElementsByTagName(s)[0];
  //   if (d.getElementById(id)) return;
  //   js = d.createElement(s);
  //   js.id = id;
  //   js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
  //   fjs.parentNode.insertBefore(js, fjs);
  // })(document, "script", "facebook-jssdk");

  return (
    <div className="App">
      <BrowserRouter>
        {/* <header className="App-header"> */} {/* <Header /> */} <HomePage />
        <Footer />
        <div id="hit-counter"> </div>
        {/* Messenger Chat plugin Code  */}
        {/* <div id="fb-root"></div> */}
        {/* Your Chat plugin code */}
        {/* <div id="fb-customer-chat" className="fb-customerchat"></div> */}
      </BrowserRouter>
    </div>
  );
}

export default App;

import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyD_8rPZtc4He9UlToQ4jE_19dbITptBwyU",
    authDomain: "jmbc-web.firebaseapp.com",
    databaseURL: "https://jmbc-web-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "jmbc-web",
    storageBucket: "jmbc-web.appspot.com",
    messagingSenderId: "456410390338",
    appId: "1:456410390338:web:c672ee0b737fd745d5f2db",
    measurementId: "G-89LMNGVZ8E",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const database = app.database();
export {
    app,
    // analytics,
    database,
};
import React, { useState } from "react";
import "./footerStyle.css";
import { ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import Constant from "../../utils/constants";

const Footer = () => {
  const [activeTab, changeTab] = useState(Constant.HOME_TAB);

  const [dropMenu, toggleDropMenu] = useState(false);

  // let menuToggle = document.querySelector(".toggle");
  // let navigation = document.querySelector(".navigation");

  const foterNavItem = (tabName, sectionId) => {
    return (
      <li>
        <Link
          // to="/"
          to={`/${sectionId.replace("-section", "")}`}
          className={`${tabName === activeTab && "active"}`}
          onClick={() => {
            let sectionEL = document.getElementById(sectionId);
            sectionEL &&
              sectionEL.scrollIntoView({ behavior: "smooth", block: "start" });

            changeTab(tabName);
            toggleDropMenu(!dropMenu);
          }}
        >
          {tabName}
        </Link>
      </li>
    );
  };

  return (
    <div
      style={{
        backgroundImage:
          "linear-gradient(0deg, rgb(185 46 29), rgb(185 46 29 / 50%)),url(/assets/img/gold_leaf_bg.webp)",
        // backgroundImage:
        // "linear-gradient(0deg, rgb(196 22 28), rgb(196 22 28))",
        // background: "#242424",
      }}
      className="footer-container"
      id="footer-section"
    >
      <div className="footer-top--container">
        <div className="getintouch-container">
          <h1>Get In Touch</h1>

          <div className="social-footer">
            <a href="https://www.facebook.com/jmbcnepal" target="_blank">
              <img src="/assets/icons/facebook.svg" alt="facebook" />
            </a>
            <a href="https://www.instagram.com/jmbcnepal" target="_blank">
              <img src="/assets/icons/instagram.svg" alt="instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/jmbcnepal"
              target="_blank"
            >
              <img src="/assets/icons/linkedin.svg" alt="linkedin" />
            </a>
            <a
              href="https://wa.me/9779852029315"
              target="_blank"
              style={{ filter: "invert(0)" }}
            >
              <img src="/assets/icons/whatsapp-green.svg" alt="whatsapp" />
            </a>
          </div>

          <div className="footer-address-div">
            <p className="icon-map">
              <i className="fa fa-map-marker"></i>
              <b>Address: </b>
              Kathari 3, Morang, Nepal.
            </p>
            <p>
              <i className="fa fa-phone"></i>
              <b>Phone: </b>{" "}
              <a href="tel:+9779852029315">+9779852029315 / +9779811003320 </a>
            </p>
            {/* <p>
              
              <a href="https://wa.me/9779852029315" target="_blank">
                <img
                  src="/assets/icons/whatsapp.svg"
                  style={{ height: 18 }}
                  alt="WhatsApp"
                />
                {"  WhatsApp"}
              </a>
            </p> */}
            <p>
              <i className="fa fa-envelope"></i>
              <b>Email: </b>
              <a href="mailto:jmbc.nepal@gmail.com"> jmbc.nepal@gmail.com</a>
            </p>
          </div>
        </div>

        <ul className={`navigation ${dropMenu ? "active" : ""}`}>
          {/* {foterNavItem("Home", "home-section")} */}
          {foterNavItem(Constant.PRODUCT_TAB, "products-section")}
          {/* {navItem("What's New")} */}
          {foterNavItem(Constant.ABOUT_TAB, "about-section")}
          {foterNavItem(Constant.CONTACT_TAB, "contact-section")}
        </ul>
      </div>

      <div>
        <a
          href="#home"
          className="logo"
          style={{ display: "flex", alignItems: "center" }}
          // style={{window.innerWidth > 768 ? '800px' : '400px'}}
        >
          <img
            src="/assets/icons/jmbc-logo.png"
            style={{ width: 64, marginRight: 10 }}
            alt="Jai Maa Bhagwati Confectionery Udyog | JMBC Candy"
          />
          {"© Jai Maa Bhagwati Confectionery Udyog "}
          {new Date().getFullYear()}
          {", All Rights Reserved"}
        </a>
      </div>
    </div>
  );
};

export default Footer;

// import { useMediaQuery } from "react-responsive";

const Utils = {
  screenSize: () => {},

  getIngredients: (arr) => {
    if (arr) {
      return arr.join(", ");
    }
    return "";
  },

  scrollToTop: () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },

  scrollToBottom: () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  },

  scrollTo: (className) => {
    // if (className)
    //   window.scrollTo({
    //     top: document.querySelector(`#${"." + className}`).scrollHeight,
    //     left: 0,
    //     behavior: "smooth",
    //   });
  },
};

export default Utils;

import React, { useState, useEffect, Suspense, useRef } from "react";
import "./homePageStyle.css";
// import BottomThumbSlider from "./bottomThumbSlider";
import Utils from "../../utils/utility";

// import ProductsPage from "../productsPage/productsPage";
// import AboutPage from "../aboutPage/aboutPage";
import Header from "../../components/header/header";
// import ContactPage from "../contactPage/contactPage";
import loadable from "@loadable/component";
import Constant from "../../utils/constants";

const AboutPage = loadable(() => import("../aboutPage/aboutPage"));
const ProductsPage = loadable(() => import("../productsPage/productsPage"));
const BottomThumbSlider = loadable(() => import("./bottomThumbSlider"));

const HomePage = () => {
  const [selectedCandy, changeCandy] = useState({});
  const [candyList, setcandyList] = useState([]);
  const candyDataList = require("./../../data/candyNames.json");
  const headerRef = useRef();

  useEffect(() => {
    if (candyDataList) {
      setcandyList(candyDataList);
      changeCandy(candyDataList[0]);
    }
  }, [candyDataList]);

  return (
    <div
    //   style={{
    //     backgroundColor:
    //       selectedCandy && selectedCandy.bgColor ? selectedCandy.bgColor : "",
    //   }}
    >
      <Header
        bgColor={selectedCandy ? selectedCandy.bgColor : ""}
        ref={headerRef}
      />
      <section id="home-section" className="homepage_section">
        <div
          className="bg"
          style={{
            backgroundColor:
              selectedCandy && selectedCandy.bgColor
                ? selectedCandy.bgColor
                : "",
          }}
        ></div>
        <div
          className="bg2 glass-page"
          style={{ background: "url(/assets/img/gold_leaf_bg.webp)" }}
        ></div>
        {/* <Header /> */}
        <div className="content">
          <div className="textBox">
            <h1> {selectedCandy && selectedCandy.name + " Candy"} </h1>
            <p>
              {Utils.getIngredients(selectedCandy && selectedCandy.ingredients)}
            </p>
            {/* <h2>{"Buy, Eat, Enjoy"}</h2> */}{" "}
            <p>
              {/* Candies are delightful treat desired and craved by every person
            							around the globe. */}{" "}
              {/* There can be no kids or adults who can resist
            							the sweet taste of this yummy colorful sugary candy. They are
            							considered as the best ideal gift for special occasions such as
            							Valentine’s Day, Mother’s Day, Birthdays, Christmas and more. */}{" "}
            </p>
            <a
              className="home-contactNow-txt"
              href="#"
              onClick={() => {
                Utils.scrollToBottom();

                headerRef.current.onTabChanged(Constant.CONTACT_TAB);
              }}
            >
              Contact Now
            </a>
          </div>
          <div className="imgBox">
            <img
              src={selectedCandy && selectedCandy.image}
              loading="lazy"
              className="candyImg"
              alt={selectedCandy && selectedCandy.name}
              onContextMenu="return false;"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/icons/candy-placeholder.svg";
              }}
            />
            <div className="image-overlay"></div>

          </div>  
        </div>
        <Suspense>
          <BottomThumbSlider
            data={candyList}
            onThumbClicked={(idx) => {
              changeCandy(candyList[idx]);
            }}
          />
        </Suspense>

        <div className="social">
          <a href="https://www.facebook.com/jmbcnepal" target="_blank">
            <img src="/assets/icons/facebook.svg" alt="facebook" />
          </a>
          <a href="https://www.instagram.com/jmbcnepal" target="_blank">
            <img src="/assets/icons/instagram.svg" alt="instagram" />
          </a>
          <a href="https://www.linkedin.com/company/jmbcnepal" target="_blank">
            <img src="/assets/icons/linkedin.svg" alt="linkedin" />
          </a>
          <a
            href="https://wa.me/9779852029315"
            target="_blank"
            // style={{ filter: "invert(0)" }}
          >
            <img src="/assets/icons/whatsapp-green.svg" alt="whatsapp" />
          </a>
        </div>
      </section>
      {/* <AsyncPage page="productsPage/productsPage" />
      <AsyncPage page="aboutPage/aboutPage" /> */}
      {/* <AsyncPage page="ContactPage" /> */}

      <Suspense>
        <ProductsPage />
        <AboutPage />
        {/* <ContactPage /> */}
      </Suspense>
    </div>
  );
};

export default HomePage;
